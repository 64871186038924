<template>

  <div>

    <b-overlay
      :show="submitting"
      rounded="sm"
    >
      <b-card

        title="Request Reports Range"
      >



        <b-form
          ref="requestForm"
          @submit.prevent
        >
          <b-row>

            <b-col cols="12">
              <b-form-group
                label="Report type"
                label-for="v-type"
                required
              >
                <b-form-select
                  v-model="requestsRange.reportType"
                  :options="reportsOptions"
                />

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Start Day"
                label-for="v-startDay"
              >

                <flat-pickr

                  id="v-startDate"
                  v-model="requestsRange.startDate"
                  placeholder="Start Day"
                  required
                  class="form-control"
                />

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="End Day"
                label-for="v-endDay"
              >

                <flat-pickr

                  id="v-endDay"
                  v-model="requestsRange.endDate"
                  placeholder="End Day"
                  required
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">

              <b-form-group
                label="Period (Daily, Weekly)"
                label-for="v-daily"
              >
                <b-form-select :options="periodOptions" />

              </b-form-group>

            </b-col>



              <b-alert
                  variant="warning"
                  show
                  class="m-2"
                >

                  <div class="alert-body">
                    <span><strong>NB </strong> The maximun range is <b>180 days </b>... requesting less is recommended. </span>

                  </div>

              </b-alert>
 

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="submitting"
                @click="requestRange"
              >
                Request reports
              </b-button>

            </b-col>

          </b-row>
        </b-form>

      </b-card>

    </b-overlay>

    <b-card-code
      no-body
      title="Ranges(Reports)"
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="logs"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>
          </template>

          <template #cell(status)="data">

            <b-badge :variant="data.item.status== 'error' ? 'danger' : data.item.status">
              {{ data.item.status }}
            </b-badge>

          </template>

          <template #cell(reportType)="data">
            {{ data.item.reportType }} {{ data.item.reportCategory ? '(' + data.item.reportCategory + ')' : '' }}
          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime }}
          </template>

          <template #cell(startDate)="data">
            {{ data.item.startDate|formatDate('UTC') }}
          </template>

          <template #cell(endDate)="data">
            {{ data.item.endDate|formatDate('UTC') }}
          </template>

          <template #cell(reports)="row">

            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            {{ row.item.reports.length }}
            <b-form-checkbox
              v-if="row.item.reports.length"
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            >
              <span>{{ row.detailsShowing ? 'Hide' : 'Show' }} </span>
            </b-form-checkbox>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-table
                :items="row.item.reports"
                :fields="childrenFields"
                :tbody-tr-class="'table-primary'"
                striped
                responsive
                size="sm"
                class="mb-0"
              >
                <template #cell(id)="data">
                  <span v-b-tooltip.hover.top="data.item.id">
                    #
                  </span>
                </template>

                <template #cell(tries)="data">
                  reqs:{{ data.item.tries.requests }}
                  /downloads:{{ data.item.tries.downloads }}
                </template>

                <template #cell(createdAt)="data">
                  {{ data.item.createdAt|formatDateTime('UTC') }}
                </template>

                <template #cell(dataStartTime)="data">
                  {{ data.item.dataStartTime|formatDate('UTC')  }}
                </template>

                <template #cell(requestedAt)="data">
                  {{ data.item.requestedAt|formatDateTime('UTC') }}
                </template>

                <template #cell(documentAt)="data">
                  {{ data.item.documentAt|formatDateTime('UTC') }}
                </template>

              </b-table>

            </b-card>
          </template>

          <template #cell(actions)="data">

                <a
                  class="text-danger"
                  v-if="data.item.totalUnRequested>0"
                  @click="cancel(data.item.id)"
                >Cancel</a>
              </template>


        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCard, BCol, BForm, BRow, BBadge, BFormGroup, BFormInput,BAlert,
  BFormSelect,
  BFormCheckbox, BButton,
  BPagination, VBTooltip, BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BForm,
    BAlert,
    BCol,
    BRow,
    BFormGroup,
    BCardCode,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      requestsRange: {},
      loading: false,
      submitting: false,
      currentPage: 1,
      accountId: null,
      logs: [],
      perPage: 25,
      fields: ['id', 'reportType', 'reports', 'startDate', 'endDate', 'createdAt', 'actions'],
      childrenFields: ['id', 'dataStartTime', 'reportId', 'requestedAt', 'documentAt', 'tries', 'createdAt'],
      current: 1,
      rows: 0,
      reportsOptions: [
        { value: 'GET_LEDGER_SUMMARY_VIEW_DATA', text: 'GET_LEDGER_SUMMARY_VIEW_DATA' },
        { value: 'GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA', text: 'GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA' },
        { value: 'GET_SALES_AND_TRAFFIC_REPORT:CHILD', text: 'GET_SALES_AND_TRAFFIC_REPORT (CHILD)' },
        { value: 'GET_SALES_AND_TRAFFIC_REPORT:PARENT', text: 'GET_SALES_AND_TRAFFIC_REPORT  (PARENT)' },
      ],
      periodOptions: [
        { value: 'DAILY', text: 'Daily' },
      ],

    }
  },

  mounted() {
    this.accountId = this.$route.params.accountId
    this.requestsRange.accountId = this.accountId
    this.loadReportsRanges(this.accountId, 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadReportsRanges(this.accountId, this.page)
    },

    loadReportsRanges(accountId, page = 1) {
      this.loading = true
      this.$store.dispatch('amzReportsRange/fetchAll', { accountId, page })
        .then(result => {
          this.logs = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    requestRange() {
      this.submitting = true
      this.$store.dispatch('amzReportsRange/create', this.requestsRange)
        .then(() => {
          this.showToast('Success', 'Requested successfully', 'success')
          this.loadReportsRanges(this.accountId, this.page)

          this.submitting = false

          this.$refs.requestForm.reset()
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while requesting', 'danger')
          this.submitting = false
        })
    },

    cancel(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.$store.dispatch('amzReportsRange/cancel', id).then(
            () => {
              this.showToast('Success', 'Canceled with success', 'success')
              this.loadReportsRanges(this.accountId)
            },
            error => {
              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while canceling item', 'danger')
            },
          )
        }
      },
      error => {
        console.log(err)
      })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
